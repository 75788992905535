
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


// import p1 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture1.png';
// import p2 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture2.png';
// import p3 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture3.png';
// import p4 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture4.png';
// import p5 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture5.png';
// import p6 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture6.png';
// import p7 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture7.png';
// import p8 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture8.png';
// import p9 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture9.png';
// import p10 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture10.png';
// import p11 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture11.png';
// import p12 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture12.png';
// import p13 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture13.png';
// import p14 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture14.png';
// import p15 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture15.png';
// import p16 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture16.png';
// import p17 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture17.png';
// import p18 from 'assests/Photos/SchoolProgram/ChildrensDay/Picture18.png';




import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/SchoolProgram/components/SidebarArticles/SidebarArticles';

const ChildrensDay = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture6.webp`;
  const p7 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture7.webp`;
  const p8 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture8.webp`;
  const p9 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture9.webp`;
  const p10 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture10.webp`;
  const p11 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture11.webp`;
  const p12 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture12.webp`;
  const p13 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture13.webp`;
  const p14 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture14.webp`;
  const p15 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture15.webp`;
  const p16 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture16.webp`;
  const p17 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture17.webp`;
  const p18 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/Childrensday/Picture18.webp`;
  
  
  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source:p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },
      {
        src: p9,
        source: p9,
        rows: 1,
        cols: 1,
      },
      {
        src: p10,
        source:p10,
        rows: 1,
        cols: 1,
      },
      {
        src: p11,
        source: p11,
        rows: 1,
        cols: 1,
      },
      {
        src: p12,
        source: p12,
        rows: 1,
        cols: 1,
      },
      {
        src: p13,
        source:p13,
        rows: 1,
        cols: 1,
      },
      {
        src: p14,
        source: p14,
        rows: 1,
        cols: 1,
      },
      {
        src: p15,
        source: p15,
        rows: 1,
        cols: 1,
      },
      {
        src: p16,
        source: p16,
        rows: 1,
        cols: 1,
      },
      {
        src: p17,
        source: p17,
        rows: 1,
        cols: 1,
      },
      {
        src: p18,
        source: p18,
        rows: 1,
        cols: 1,
      },


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Children’s Day
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
        Class: Montessori &amp;&  Kindergarten Date: 14-11-2022
        </Typography>
        <br/>
        <Typography
          variant={'h5'}
        //   color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        >
          “Children are like buds in a garden and should be carefully and lovingly nurtured, as
they are the future of our nation and the citizens of tomorrow.” Pt. Jawaharlal Nehru”
<br/>
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        We were all children once. We all share the desire for the well-being of our children,
which has always been and will continue to be the most universally cherished aspiration
of humankind. A day marking childhood – ‘Children’s day’ focuses on children and their
enjoyment. Every year since 1964, 14 November is celebrated as Children’s Day in India.
It is the birth anniversary of the legendary freedom fighter and first Prime Minister of
independent India, Pandit. Jawaharlal Nehru, popularly known as ‘Chacha Nehru’.<br/><br/>
This joyous occasion was celebrated with grand splendour and fiesta at National Public
School, Yeshwanthpur and was extra special as it marked the decennial year celebration
of Children’s Day at School. The stage was set for the students of Pre-Primary to enjoy a
week-long festival of Children’s Day fun activities.<br/><br/>
On Monday, 14 November 2022, the ambience was aptly set with colourful streamers,
balloons, magic wands, magician hats and much more which kept the students curious.
Teachers turned into Magicians and held the students in their magical spell. This visual
experience was filled with excitement and astonishment for everyone. They received
delectable goodies on their special day and revelled in wonder.<br/><br/>
The festivities continued through the following days beginning with a ‘Treasure Hunt’ on
Tuesday, 15 November 2022. The students turned into tiny Sherlocks’ who followed the
clues given by their teachers and traced the hidden treasure in each classroom. The
students gleamed in awe and excitement upon unboxing their treasure and were happy
to find a pack of golden stars, a smiley ball and reward badges. The curiosity continued
through Wednesday, 16 November 2022 where a host of one-minute games and a
fabulous dance floor was in store for the children. All the students participated in the
quiz games, sang and danced to their favourite rhymes and thoroughly enjoyed the fun
activities.<br/><br/>
From master minds to master chefs, our students embrace every contest! The theme for
Day Four, Thursday 17 November 2022 was a unique gastronomical galore. Students
made healthy and nutritious dry fruit ladoos and cherished their creations with family
and friends. Day Five, the culmination day was a time to rejoice as Friday, 18 November

2022 was the highlight of the week-long celebration. Art-a-Fair - a day dedicated for art
activities was a treat to the eyes. From bubbles to splash, to shake and print, students
enjoyed visiting various Art Stations and explored art through multi-dimensional learning
platforms. The No Bag Day symbolically represented the idea that students not always
need books and stationery to learn.<br/><br/>
The campus was buzzing with excitement and joy as it was a day wherein fun and frolic
dominated. There was excitement in the air and the little ones left the school with a bag
full of stories about their special day at school. <br/><br/>
        </Typography>
      </Box>
      <Typography
          variant={'h5'}
        //   color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
          “The earth reveals its innocence through the smiles of children. A very warm wish for all
the children on this special day. Happy Children’s Day!”
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    <Typography>
    <strong>Links of Videos and Photographs of Children’s Day Event</strong><br/>

Video Link for Magic Show – <a href="https://bit.ly/3VdUQR4">https://bit.ly/3VdUQR4</a><br/>
Video Link for Fun activities – <a href="https://bit.ly/3OFcX07">https://bit.ly/3OFcX07</a><br/>
Picasa Album Link – Montessori –<a href="https://photos.app.goo.gl/ioiG2fm5RfyivdD1A"> https://photos.app.goo.gl/ioiG2fm5RfyivdD1A</a><br/>
Picasa Album Link – Kindergarten -<a href="https://photos.app.goo.gl/CH2Hb61fGtKaJcMj6"> https://photos.app.goo.gl/CH2Hb61fGtKaJcMj6</a><br/>
    </Typography>
    </Grid>
   
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ChildrensDay;